import React from 'react'
import AdSense from 'react-adsense'

/*
<!-- 디스플레이-사각형-반응형 -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="autorelaxed"
     data-ad-client="ca-pub-2695370852236805"
     data-ad-slot="4419929143"></ins>
<script>
*/
const AdSenseMultiplex = () => (
  <AdSense.Google
    client="ca-pub-2695370852236805"
    slot="4419929143"
    className="adsbygoogle"
    style={{ display: 'block' }}
    format="autorelaxed"
  />
)

export default AdSenseMultiplex
